import * as React from 'react'
import '../assets/css/index.css'
import Navigation from '../components/Navigation'; 
import Footer from '../components/Footer'
import { Container, Modal, Col, Row, Button } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from 'gatsby-plugin-next-seo';

// markup
const IndexPage = () => {
  return (
    <main>
      <Navigation/>
      <GatsbySeo
        title='Coaching at Pinches Cue Club & The Venue - Snooker, Pool and Darts | Norwich Snooker and Pool Club | Private Function Room'
        description='Looking for somewhere to improve your snooker or pool in Norwich? Pinches Cue Club is one of the best cue sports venues in Norwich, find out more about our coaching options here'
        />
      <div className='green-section'>
      <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="green-section">
                  <h1 className='pt-3 pb-3'>
                  Norwich Snooker Coaching Information 
                  </h1>
                  <p>We have two resident snooker coaches that operate at our snooker club, both providing a wide range of coaching for different level of abilities</p>
                  <h2 className='pb-3'>Junior Weekend Coaching Club</h2>
                  <p>We have a bi-weekly junior coaching club open to juniors of all abilities aged between 7-18, this is run by local Level 1 coach Jimmy Cross</p>
                  <h3>Jimmy Cross Bio-</h3>
                  <p>Jimmy Cross from Cross Q Coaching is the resident coach at The Pinches Club. Jimmy is an accredited WPBSA (Level 1), SightRight & SQ (Cert Pending) snooker coach.  He offers 1-2-1 coaching sessions with a focus on technique and mind skills, covering all cue sports (snooker, pool, billiards etc). From September 2022 Jimmy will be running a bi-weekly coaching club for juniors. For more details you can contact Jimmy either by email.</p>
                  <Button href="https://wpbsa.com/coaches/james-cross/" variant="success" target="_blank" className=''>More information</Button>
                  <br></br>
                  <p>Email: <a href="mailto:jimmycross75@gmail.com">jimmycross75@gmail.com</a></p>
                  <p>Telephone: <a href="tel:07774455467">07774455467</a></p>
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12} className="green-section">
                    <StaticImage src="../assets/img/jimmy-cross-norwich.jpg"
                        alt="A hero image"
                        placeholder="blurred"
                        layout="constrained"
                        className='' />
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12} className="green-section">

                  <StaticImage src="../assets/img/Jimmy-cross-norwich-coaching (1).jpg"
                        alt="A hero image"
                        placeholder="blurred"
                        layout="constrained"
                        className='' />
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12} className="green-section">
                  <StaticImage src="../assets/img/Jimmy-cross-norwich-coaching (2).jpg"
                        alt="A hero image"
                        placeholder="blurred"
                        layout="constrained"
                        className='' />
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12} className="green-section">
                  <StaticImage src="../assets/img/Jimmy-cross-norwich-coaching (3).jpg"
                        alt="A hero image"
                        placeholder="blurred"
                        layout="constrained"
                        className='' />
                  </Col>
              <hr></hr>
              </Row>
      </Container>
            <Footer/>
      </div>

    </main>
  )
}

export default IndexPage
